import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import CreditScoreMeter from "./CreditMeter";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import CreditDefaultHistory from "./CreditDefaultHistory";
import {creditSessionStatusEnum} from "app/enums/gold_loan/recommendation";
import {activeLoansSelectors, activeLoansActions} from "app/store/activeLoans";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {IGLoading} from "app/components";
import {resetCreditDetails} from "app/store/loanRenewalRebook/creditDetails/creditDetails.reducer";
import {resetGuarantorPersonalDetails} from "app/store/loanRenewalRebook/personalDetails/personalDetails.reducer";

interface BTScoreCreditDetailsProps {
  crId: number;
}
const BTScoreCreditDetails = ({crId}: BTScoreCreditDetailsProps) => {
  const dispatch = useAppDispatch();
  const {status: creditSessionStatus} =
    useAppSelector(creditDetailsSelectors.getCreditSessionStatus) ?? {};

  const creditReportStatus = useAppSelector(activeLoansSelectors.getCreditReportStatus);
  const {cibilScrore} =
    useAppSelector(personalDetailsSelectors.getGuarantorPersonalDetails) ?? {};

  const {getCreditDefaultHistory: isCreditDefaultHistoryLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const {getCreditSessionStatus: isCreditSessionLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );

  const isLoading = isCreditSessionLoading || isCreditDefaultHistoryLoading;
  useEffect(() => {
    if (crId) {
      dispatch(
        creditDetailsActions.getCreditSessionStatus({
          crId,
          cibilConsentType: "TAKEOVER_CIBIL_CONSENT",
        }),
      );
      dispatch(
        personalDetailsActions.getGuarantorPersonalDetails({
          crId,
          personalDetailType: "TAKEOVER_PERSONAL_DETAIL",
        }),
      );
      dispatch(
        activeLoansActions.getCreditDefaultHistory({
          crId,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (creditSessionStatus === creditSessionStatusEnum.SUCCESS) {
      dispatch(
        activeLoansActions.getCreditReportStatus({
          crId,
        }),
      );
    }
  }, [creditSessionStatus]);

  useEffect(() => {
    if (creditReportStatus === creditSessionStatusEnum.SUCCESS) {
      dispatch(
        creditDetailsActions.getTakeoverCreditReportHtml({
          crId,
          cibilConsentType: "TAKEOVER_CIBIL_CONSENT",
        }),
      );
    }
  }, [creditReportStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetCreditDetails());
      dispatch(resetGuarantorPersonalDetails());
    };
  }, []);

  if (isLoading) {
    return <IGLoading height="10vh" />;
  }
  return (
    <Box
      bgcolor="#FAFAFA"
      p="24px 50px 24px 24px"
      borderRight="1px solid #0000001F"
      borderLeft="1px solid #0000001F"
    >
      <Typography my={1} variant="h6" fontWeight={500}>
        Credit details
      </Typography>
      <CreditScoreMeter cibilScore={Number(cibilScrore)} crId={Number(crId)} />
      <Typography my={1} variant="h6" fontWeight={500}>
        Loan Default History
      </Typography>
      <CreditDefaultHistory crId={Number(crId)} />
    </Box>
  );
};

export default BTScoreCreditDetails;
