import {LMInsuranceDetails} from "app/models/activeLoans/activeLoans.model";
import {
  ActiveLoansApiInterface,
  ActiveLoansResources,
  GetActiveLoansCRListPayload,
  AssignCRToMePayload,
  SendBackToSchedulingPayload,
  AssignOrRemoveCurrentLMPayload,
  CloseCurrentLMPayload,
  CrIdPayload,
  LoanAmountUpdateRequestPayload,
  LoanRenewalAmountDetailsPayload,
  LatestStatusPayload,
  ValidateTakeoverAmountPayload,
  LMInsuranceDetailsPayload,
  LenderIfscStatusPayload,
  SkipOfficeReviewPayload,
  LandDocumentDetailPayload,
  MarkLandDocumentPayload,
  VerifyTakeoverAccountPayload,
  RejectCrPayload,
  SendBackCrPayload,
  GetBtScoreCardConfig,
} from "./types";
import {goldApiService} from "app/infra/plugins/axios";

const ACTIVE_LOANS_ENDPOINTS: ActiveLoansResources = {
  GET_CITIES_LIST: {
    URL: "/admin/business/city-list",
    METHOD: "GET",
  },
  GET_CR_LIST: {
    URL: "/admin/business/cr-list",
    METHOD: "GET",
  },
  ASSIGN_CREDIT_MANAGER: {
    URL: "/admin/business/cr/:crId/credit-manager",
    METHOD: "POST",
  },
  REMOVE_CREDIT_MANAGER: {
    URL: "/admin/business/cr/:crId/remove-credit-manager",
    METHOD: "POST",
  },
  CLOSE_CUSTOMER_REQUEST: {
    URL: "/admin/business/cr/:crId/close",
    METHOD: "POST",
  },
  SEND_BACK_TO_SCHEDULING: {
    URL: "/admin/business/cr/:crId/scheduling",
    METHOD: "POST",
  },
  GET_LM_JOB_DETAILS: {
    URL: "admin/business/cr/:crId/lm-job",
    METHOD: "GET",
  },
  GET_LM_USERS: {
    URL: "/admin/business/agent-list",
    METHOD: "GET",
  },
  ASSIGN_CURRENT_LM: {
    URL: "/admin/business/cr-job/:crJobId/assign",
    METHOD: "PUT",
  },

  REMOVE_CURRENT_LM: {
    URL: "/admin/business/cr-job/:crJobId/remove",
    METHOD: "PUT",
  },
  CLOSE_LM_JOB: {
    URL: "/admin/business/cr/:crId/close-lm-job",
    METHOD: "POST",
  },
  BLACKLIST_LOAN: {
    URL: "/admin/loan-blacklist/list",
    METHOD: "POST",
  },
  UPDATE_LOAN_AMOUNT: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId",
    METHOD: "PUT",
  },
  GET_LOAN_TOP_UP_AMOUNT: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId/loan-top-up-amount",
    METHOD: "GET",
  },
  GET_LOAN_MAKER_FIELDS_FLAG: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId/pl-gl-maker-screen-display-input-fields",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_DETAILS: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId/cm-adjustment-renewal-detail",
    METHOD: "GET",
  },
  POST_LOAN_RENEWAL_DETAILS: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId/cm-adjustment-renewal-detail",
    METHOD: "POST",
  },
  SKIP_PERSONAL_LOAN: {
    URL: "/admin/business/cr/:crId/skip-pl",
    METHOD: "PUT",
  },
  BLACKLIST_LOAN_NEW: {
    URL: "/api/v1/admin/fraud/blacklist",
    METHOD: "POST",
  },
  LATEST_JOB_STATUS: {
    URL: "/admin/business/cr/:crId/cr-job-type/:crJobType/latest-status",
    METHOD: "GET",
  },
  LATEST_JOB_STATUS_RENEW_REBOOK: {
    URL: "/admin/renew-rebook/cr/:crId/cr-job-type/:crJobType/latest-status",
    METHOD: "GET",
  },
  VALIDATE_TAKEOVER_AMOUNT: {
    URL: "/admin/business/cr/:crId/validate-takeover-amount",
    METHOD: "PUT",
  },
  REDO_TAKEOVER_MAKER: {
    URL: "/admin/business/cr/:crId/redo-takeover-amount-maker",
    METHOD: "PUT",
  },
  BLACK_LIST_CHECK_REQUIRED: {
    URL: "/admin/business/cr/:crId/blacklist-check-required",
    METHOD: "GET",
  },
  GET_LM_INSURANCE_DETAILS: {
    URL: "/business/insurance/cr/:crId",
    METHOD: "GET",
  },

  GET_INSURANCE_FEATURE_FLAG: {
    URL: "/business/cr-feature-flag/cr/:crId/cr-feature-flag-list",
    METHOD: "GET",
  },
  GET_LENDER_IFSC_STATUS: {
    URL: "admin/bank-detail/cr/:crId/verify-ifsc-with-lender",
    METHOD: "GET",
  },
  SKIP_OFFICE_REVIEW: {
    URL: "/business-dashboard/office-review/cr/:crId/skip-office-review",
    METHOD: "POST",
  },

  GET_LAND_DOCUMENT_DETAIL: {
    URL: "/business-dashboard/land-document/cr/:crId/detail",
    METHOD: "GET",
  },

  DELETE_LAND_DOCUMENT_DETAIL: {
    URL: "/business-dashboard/land-document/cr/:crId/detail",
    METHOD: "DELETE",
  },
  GET_MATCHING_DOCUMENT_DETAIL: {
    URL: "/business-dashboard/land-document/cr/:crId/matching-list",
    METHOD: "GET",
  },
  POST_LAND_DOCUMENT_DETAIL: {
    URL: "/business-dashboard/land-document/cr/:crId/detail",
    METHOD: "POST",
  },

  MARK_LAND_DOCUMENT: {
    URL: "/business-dashboard/land-document/cr/:crId/mark-matching",
    METHOD: "PUT",
  },

  GET_LAND_DOCUMENT_STATE_LIST: {
    URL: "/business-dashboard/land-document/state-list",
    METHOD: "GET",
  },

  GET_LAND_OWNER_BORROWER_LIST: {
    URL: "/business-dashboard/land-document/borrower-owner-relation-mapping",
    METHOD: "GET",
  },
  MARK_LAND_DOCUMENT_AS_SAME: {
    URL: "/business-dashboard/land-document/cr/:crId/mark-new",
    METHOD: "PUT",
  },

  GET_AGRI_EXPOSURE_AMOUNT: {
    URL: "/business-dashboard/land-document/cr/:crId/exposure",
    METHOD: "GET",
  },

  ACTIVATE_LOAN_MAKER: {
    URL: "admin/business/loan/cr/:crId/activate-loan-maker-flow",
    METHOD: "POST",
  },

  GET_PREFILLED_MAKER_DETAILS: {
    URL: "/admin/business/dashboard/loan-amount/cr/:crId/prefilled-gold-loan-detail",
    METHOD: "GET",
  },

  GET_POST_LOAN_DOCS_FLAG: {
    URL: "/admin/document/cr/:crId/post-loan-creation-document-detail",
    METHOD: "GET",
  },
  GENERATE_DUPLICATE_CR: {
    URL: "/cr/copy",
    METHOD: "POST",
  },
  VERIFY_TAKEOVER_ACCOUNT: {
    URL: "/admin/business/cr-takeover/cr/:crId/crJob/:crJobId/verify-takeover-account-number",
    METHOD: "POST",
  },

  GET_LOAN_REQUIREMENT_REASON: {
    URL: "business/cr/:crId/attribute",
    METHOD: "GET",
  },

  GET_CREDIT_QUEUE_LOANS: {
    URL: "/admin/business/cr-list/credit-manager",
    METHOD: "GET",
  },

  GET_ACTIVE_LOANS_TABS_LIST: {
    URL: "/admin/business/cm-dashboard/active-loan/tab-list",
    METHOD: "GET",
  },

  REJECT_CR: {
    URL: "/business-dashboard/cr-rejection/rejection/cr/:crId/detail",
    METHOD: "POST",
  },

  GET_REJECT_CR_CONFIG: {
    URL: "/business-dashboard/cr-rejection/rejection/config",
    METHOD: "GET",
  },

  SEND_BACK_CR: {
    URL: "/business-dashboard/cr-rejection/send-back/cr/:crId/detail",
    METHOD: "POST",
  },

  GET_SEND_BACK_CR_CONFIG: {
    URL: "/business-dashboard/cr-rejection/send-back/config",
    METHOD: "GET",
  },

  GET_CHECKER_STATUS: {
    URL: "/admin/lender/approval/cr/:crId/detail",
    METHOD: "GET",
  },
  SEND_APPROVAL_MAIL_TO_LENDER: {
    URL: "/admin/lender/approval/cr/:crId/send-mail-to-shivalik",
    METHOD: "PUT",
  },
  REQUEST_LENDER_APPROVAL: {
    URL: "/admin/lender/approval/cr/:crId/resolve-failed-job",
    METHOD: "PUT",
  },
  GET_BT_SCORE_CARD_DETAILS: {
    URL: "/api/v1/dashboard/bt-score-card/cr/:crId",
    METHOD: "GET",
  },
  GET_BT_SCORE_CARD_CONFIG: {
    URL: "/api/v1/dashboard/bt-score-card/cr/:crId/config",
    METHOD: "GET",
  },
  GET_BT_SCORE_CARD_CUSTOMER_DETAILS: {
    URL: "/api/v1/dashboard/bt-score-card/cr/:crId/customer-detail",
    METHOD: "GET",
  },
  GET_CREDIT_REPORT_STATUS: {
    URL: "/business/cr/:crId/credit-report-status",
    METHOD: "GET",
  },
  GET_CREDIT_DEFAULT_HISTORY: {
    URL: "/api/v1/dashboard/credit-detail/takeover/cr/:crId/loan-default-detail",
    METHOD: "GET",
  },
};

const activeLoansApi: ActiveLoansApiInterface = {
  getRejectCrConfig: async () => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_REJECT_CR_CONFIG,
    });
  },
  rejectCr: async ({
    crId,
    crJobId,
    categoryId,
    subCategoryId,
    remark,
    source,
    crJobStatus,
  }: RejectCrPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.REJECT_CR,
      options: {
        pathVars: {
          crId,
        },
        data: {
          crJobId,
          categoryId,
          subCategoryId,
          remark,
          source,
          crJobStatus,
        },
      },
    });
  },
  getSendBackCrConfig: async () => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_SEND_BACK_CR_CONFIG,
    });
  },
  sendBackCr: async ({
    crId,
    crJobId,
    categoryId,
    subCategoryId,
    remark,
    source,
    crJobStatus,
  }: SendBackCrPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.SEND_BACK_CR,
      options: {
        pathVars: {
          crId,
        },
        data: {
          crJobId,
          categoryId,
          subCategoryId,
          remark,
          source,
          crJobStatus,
        },
      },
    });
  },
  getCitiesList: async () => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_CITIES_LIST,
    });
    if (error) {
      return {error, cities: []};
    }
    return {cities: response, error: null};
  },
  getCrList: async (payload: GetActiveLoansCRListPayload) => {
    const queryParams: Record<string, any> = {
      ...payload,
    };
    const {isCmQueue} = payload;
    const {response, error} = await goldApiService({
      resource: isCmQueue
        ? ACTIVE_LOANS_ENDPOINTS.GET_CREDIT_QUEUE_LOANS
        : ACTIVE_LOANS_ENDPOINTS.GET_CR_LIST,
      options: {
        queryParams: queryParams,
      },
    });

    if (error) {
      return {
        error,
        crList: null,
      };
    }
    return {
      error: null,
      crList: response,
    };
  },
  assignCrToMe: async (payload: AssignCRToMePayload) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.ASSIGN_CREDIT_MANAGER,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  removeCreditManager: async (crId: number) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.REMOVE_CREDIT_MANAGER,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  closeCustomerRequest: async (crId: number) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.CLOSE_CUSTOMER_REQUEST,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  sendBackToScheduling: async (payload: SendBackToSchedulingPayload) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.SEND_BACK_TO_SCHEDULING,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        data: {
          customerRequestSendBackReason: payload.reason,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  getLMJobDetails: async (crId: number) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LM_JOB_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  getLMUsers: async payload => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LM_USERS,
      options: {
        queryParams: {
          roleName: payload.roleName,
          cityId: payload.cityId,
        },
      },
    });
    if (error) {
      return {error, lmUserList: []};
    }
    return {lmUserList: response, error: null};
  },

  assignCurrentLM: async (payload: AssignOrRemoveCurrentLMPayload) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.ASSIGN_CURRENT_LM,
      options: {
        pathVars: {
          crJobId: payload.crJobId,
        },
        data: {
          remark: payload.remark,
          agentId: payload.agentId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },

  removeCurrentLM: async (payload: AssignOrRemoveCurrentLMPayload) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.REMOVE_CURRENT_LM,
      options: {
        pathVars: {
          crJobId: payload.crJobId,
        },
        data: {
          remark: payload.remark,
          agentId: payload.agentId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },

  closeLMJob: async (payload: CloseCurrentLMPayload) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.CLOSE_LM_JOB,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          crJobId: payload.crJobId,
          remark: payload.remark,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  blacklistLoan: async payload => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.BLACKLIST_LOAN,
      options: {
        data: payload,
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  blacklistLoanNew: async payload => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.BLACKLIST_LOAN_NEW,
      options: {
        data: payload,
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  getLatestJobStatus: async ({crId, crJobType, isRenewRebook = false}: LatestStatusPayload) => {
    return await goldApiService({
      resource: isRenewRebook
        ? ACTIVE_LOANS_ENDPOINTS.LATEST_JOB_STATUS_RENEW_REBOOK
        : ACTIVE_LOANS_ENDPOINTS.LATEST_JOB_STATUS,
      options: {
        pathVars: {
          crId,
          crJobType,
        },
      },
    });
  },
  validateTakeoverAmount: async ({
    crId,
    takeoverAmountToTransfer,
    takeoverAmountToTransferInWords,
  }: ValidateTakeoverAmountPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.VALIDATE_TAKEOVER_AMOUNT,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          takeoverAmountToTransfer,
          takeoverAmountToTransferInWords,
        },
      },
    });
  },
  redoTakeoverAmount: async (crId: number) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.REDO_TAKEOVER_MAKER,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  updateLoanAmount: async (payload: LoanAmountUpdateRequestPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.UPDATE_LOAN_AMOUNT,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          loanType: payload?.loanType,
        },
        data: {
          loanAmount: payload.loanAmount,
          processingFeeIncludingGst: payload.processingFeeIncludingGst,
        },
      },
    });
  },
  getLoanTopUpAmount: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LOAN_TOP_UP_AMOUNT,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  getLoanMakerFieldsFlag: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LOAN_MAKER_FIELDS_FLAG,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  getLoanRenewalDetails: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LOAN_RENEWAL_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  postLoanRenewalDetails: async ({crId, data}) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.POST_LOAN_RENEWAL_DETAILS,
      options: {
        pathVars: {crId},
        data,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  skipPersonalLoan: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.SKIP_PERSONAL_LOAN,
      options: {
        pathVars: {crId},
      },
    });
  },
  getBlackListCheckRequired: async (crId: number) => {
    const {response, error} = await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.BLACK_LIST_CHECK_REQUIRED,
      options: {
        pathVars: {
          crId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },

  getLMInsuranceDetails: async ({crId, userRole}: LMInsuranceDetailsPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LM_INSURANCE_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          userRole,
        },
      },
    });
  },

  getInsuranceFeatureFlag: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_INSURANCE_FEATURE_FLAG,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
  },

  getLenderIfscStatus: async ({crId, bankDetailType, loanType}: LenderIfscStatusPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LENDER_IFSC_STATUS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          bankDetailType: bankDetailType,
          loanType: loanType,
        },
      },
    });
  },
  skipOfficeReview: async ({crId, isSkipped}: SkipOfficeReviewPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.SKIP_OFFICE_REVIEW,
      options: {
        pathVars: {crId},
        data: {
          isSkipped,
        },
      },
    });
  },

  getLandDocumentDetail: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LAND_DOCUMENT_DETAIL,
      options: {
        pathVars: {crId},
      },
    });
  },

  deleteLandDocumentDetail: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.DELETE_LAND_DOCUMENT_DETAIL,
      options: {
        pathVars: {crId},
      },
    });
  },

  getMatchingLandDocumentDetail: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_MATCHING_DOCUMENT_DETAIL,
      options: {
        pathVars: {crId},
      },
    });
  },

  postLandDocumentDetail: async ({crId, formData, ...rest}: LandDocumentDetailPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.POST_LAND_DOCUMENT_DETAIL,

      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          ...rest,
        },
        data: formData,
      },
    });
  },

  markLandDocument: async ({
    currentDocumentId,
    matchingDocumentId,
    crId,
  }: MarkLandDocumentPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.MARK_LAND_DOCUMENT,
      options: {
        pathVars: {crId},
        data: {
          currentDocumentId,
          matchingDocumentId,
        },
      },
    });
  },
  getLandStateList: async () => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LAND_DOCUMENT_STATE_LIST,
    });
  },
  getLandOwnerBorrowerList: async () => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LAND_OWNER_BORROWER_LIST,
    });
  },

  markLandDocumentAsSame: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.MARK_LAND_DOCUMENT_AS_SAME,
      options: {
        pathVars: {crId},
      },
    });
  },

  getAgriExposureAmount: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_AGRI_EXPOSURE_AMOUNT,
      options: {
        pathVars: {crId},
      },
    });
  },
  activateLoanMaker: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.ACTIVATE_LOAN_MAKER,
      options: {
        pathVars: {crId},
      },
    });
  },

  getPrefilledLoanMakerDetails: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_PREFILLED_MAKER_DETAILS,
      options: {
        pathVars: {crId},
      },
    });
  },
  generateDuplicateCr: async ({crId}) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GENERATE_DUPLICATE_CR,
      options: {
        queryParams: {
          crId,
        },
      },
    });
  },
  getPostLoanDocsFlag: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_POST_LOAN_DOCS_FLAG,
      options: {
        pathVars: {crId},
      },
    });
  },

  verifyTakeoverAccount: async ({
    crId,
    crJobId,
    lenderBankAccountNumber,
  }: VerifyTakeoverAccountPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.VERIFY_TAKEOVER_ACCOUNT,
      options: {
        pathVars: {
          crId,
          crJobId,
        },
        data: {
          lenderBankAccountNumber,
        },
      },
    });
  },

  getLoanRequirementReason: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_LOAN_REQUIREMENT_REASON,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  getActiveLoansTabsList: async () => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_ACTIVE_LOANS_TABS_LIST,
    });
  },

  getLoanCheckerStatus: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_CHECKER_STATUS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },

  sendApprovalMailToLender: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.SEND_APPROVAL_MAIL_TO_LENDER,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },

  requestLenderApproval: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.REQUEST_LENDER_APPROVAL,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },

  getBtScoreCardDetails: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_BT_SCORE_CARD_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },

  getBtScoreCardConfig: async (payload: GetBtScoreCardConfig) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_BT_SCORE_CARD_CONFIG,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          source: payload.source,
        },
      },
    });
  },

  getBtScoreCardCustomerDetails: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_BT_SCORE_CARD_CUSTOMER_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  getCreditReportStatus: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_CREDIT_REPORT_STATUS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  getCreditDefaultHistory: async (payload: CrIdPayload) => {
    return await goldApiService({
      resource: ACTIVE_LOANS_ENDPOINTS.GET_CREDIT_DEFAULT_HISTORY,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
};
export default activeLoansApi;

export {ACTIVE_LOANS_ENDPOINTS};
