import React from "react";
import GenerateCibilScoreIcon from "../GenerateCibilScore";
import {Typography, Box} from "@mui/material";
import MeterPin from "./images/pin.png";
import PoweredBy from "./images/powered.png";
import {getCibilScoreText, getCibilRotation} from "./CreditMeterHelpers";
import styled from "@emotion/styled";
import {IGButton} from "app/components";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import {isNil} from "lodash";

interface CreditScoreMeterProps {
  cibilScore: number;
  crId: number;
}

const CreditMeterBox = styled(Box)`
  padding-top: 2rem;
  padding-bottom: 1.125rem;
  margin-top: 1.125rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CreditScoreMeter = ({cibilScore, crId}: CreditScoreMeterProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {showError, showSuccess} = useSnackBar();
  const {initiateCreditConsent: isInitiateConsentLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );
  const {consentStatus} = useAppSelector(creditDetailsSelectors.getCreditSessionStatus) ?? {};
  const creditReportHtml = useAppSelector(creditDetailsSelectors.getCreditReportHtml);

  const scoreText = getCibilScoreText(cibilScore);
  const rotation = getCibilRotation(cibilScore);

  const viewCreditReport = () => {
    if (creditReportHtml) {
      const win = window.open(
        "",
        "_blank",
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1800,height=900,",
      );
      if (win) {
        win.document.body.innerHTML = creditReportHtml;
      }
    }
  };

  return (
    <Box bgcolor="#FFFFFF" p={2} my={1} border="1px solid #0000001f" borderRadius="6px">
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight={500} mb={1}>
          Credit Score
        </Typography>
        {!consentStatus && <Typography variant="h6">Not Generated</Typography>}

        <img style={{maxWidth: "170px"}} src={PoweredBy} alt="PoweredBy" />
      </Box>

      {consentStatus && !isNil(cibilScore) && (
        <CreditMeterBox>
          <Box display="flex" justifyContent="center" alignItems="center" mb={1.75}>
            <Typography fontWeight={400}>{cibilScore || "-"}</Typography>

            <Typography mx={1} fontWeight={400}>
              |
            </Typography>

            <span style={{color: scoreText.color}}>
              <Typography fontWeight={700}>{scoreText.text}</Typography>
            </span>
          </Box>

          <Box position="relative">
            <GenerateCibilScoreIcon />
            <img
              draggable="false"
              style={{
                maxWidth: "113px",
                position: "absolute",
                right: "52px",
                bottom: "2rem",
                transition: "transform 1s ease-in-out",
                transform: `rotate(${rotation}deg)`,
              }}
              src={MeterPin}
              alt="pin"
            />
          </Box>
        </CreditMeterBox>
      )}

      {!consentStatus && (
        <Box mt={4}>
          <IGButton
            loading={isInitiateConsentLoading}
            onClick={() =>
              dispatch(
                creditDetailsActions.initiateCreditConsent({
                  crId: crId,
                  isRenewRebook: false,
                }),
              )
            }
            variant="contained"
          >
            SEND CONSENT SMS
          </IGButton>
        </Box>
      )}
      {!isNil(creditReportHtml) && (
        <IGButton onClick={() => viewCreditReport()} variant="outlined">
          VIEW CREDIT REPORT
        </IGButton>
      )}
    </Box>
  );
};

export default CreditScoreMeter;
